// 刘志坤 V2.5.2
<template>
  <div ref="parent">
    <a-modal
      centered
      :destroyOnClose="true"
      :width="658"
      :title="'查看'"
      :visible="visible"
      :footer="null"
      @cancel="$emit('update:visible', false)"
      :getContainer="() => $refs.parent"
    >
      <a-spin :spinning="currentLoading">
        <section class="content show-scrollbar">
          <section>
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <a-descriptions-item label="部门">{{ current?.departmentName || '-' }}</a-descriptions-item>
              <a-descriptions-item label="申请人">{{ current?.name || '-' }}</a-descriptions-item>
              <a-descriptions-item label="手机号">{{ current?.mobile || '-' }}</a-descriptions-item>
              <a-descriptions-item label="流程主题">{{ current?.fileName || '-' }}</a-descriptions-item>
              <!-- <a-descriptions-item v-if="current.selfSealRelList.length == 0" label="申请印章">{{ current?.sealName || '-' }}</a-descriptions-item>
              <a-descriptions-item v-if="current.selfSealRelList.length == 0" label="申请次数">{{ current?.applicationNumber || '-' }}</a-descriptions-item> -->

              <!-- 版本更新最次的方案：2.5.2 -->
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[0]">{{ current.selfSealRelList[0]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[0]">{{ current.selfSealRelList[0]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[1]">{{ current.selfSealRelList[1]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[1]">{{ current.selfSealRelList[1]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[2]">{{ current.selfSealRelList[2]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[2]">{{ current.selfSealRelList[2]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[3]">{{ current.selfSealRelList[3]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[3]">{{ current.selfSealRelList[3]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[4]">{{ current.selfSealRelList[4]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[4]">{{ current.selfSealRelList[4]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[5]">{{ current.selfSealRelList[5]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[5]">{{ current.selfSealRelList[5]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[6]">{{ current.selfSealRelList[6]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[6]">{{ current.selfSealRelList[6]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[7]">{{ current.selfSealRelList[7]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[7]">{{ current.selfSealRelList[7]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[8]">{{ current.selfSealRelList[8]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[8]">{{ current.selfSealRelList[8]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[9]">{{ current.selfSealRelList[9]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[9]">{{ current.selfSealRelList[9]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[10]">{{ current.selfSealRelList[10]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[10]">{{ current.selfSealRelList[10]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[11]">{{ current.selfSealRelList[11]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[11]">{{ current.selfSealRelList[11]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[12]">{{ current.selfSealRelList[12]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[12]">{{ current.selfSealRelList[12]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[13]">{{ current.selfSealRelList[13]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[13]">{{ current.selfSealRelList[13]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[14]">{{ current.selfSealRelList[14]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[14]">{{ current.selfSealRelList[14]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[15]">{{ current.selfSealRelList[15]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[15]">{{ current.selfSealRelList[15]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[16]">{{ current.selfSealRelList[16]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[16]">{{ current.selfSealRelList[16]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[17]">{{ current.selfSealRelList[17]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[17]">{{ current.selfSealRelList[17]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[18]">{{ current.selfSealRelList[18]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[18]">{{ current.selfSealRelList[18]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[19]">{{ current.selfSealRelList[19]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[19]">{{ current.selfSealRelList[19]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[20]">{{ current.selfSealRelList[20]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[20]">{{ current.selfSealRelList[20]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[21]">{{ current.selfSealRelList[21]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[21]">{{ current.selfSealRelList[21]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[22]">{{ current.selfSealRelList[22]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[22]">{{ current.selfSealRelList[22]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[23]">{{ current.selfSealRelList[23]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[23]">{{ current.selfSealRelList[23]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[24]">{{ current.selfSealRelList[24]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[24]">{{ current.selfSealRelList[24]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[25]">{{ current.selfSealRelList[25]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[25]">{{ current.selfSealRelList[25]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[26]">{{ current.selfSealRelList[26]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[26]">{{ current.selfSealRelList[26]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[27]">{{ current.selfSealRelList[27]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[27]">{{ current.selfSealRelList[27]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[28]">{{ current.selfSealRelList[28]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[28]">{{ current.selfSealRelList[28]?.applicationNumber }}</a-descriptions-item>
              <a-descriptions-item label="申请印章" v-if="current?.selfSealRelList[29]">{{ current.selfSealRelList[29]?.sealName }}</a-descriptions-item>
              <a-descriptions-item label="申请次数" v-if="current?.selfSealRelList[29]">{{ current.selfSealRelList[29]?.applicationNumber }}</a-descriptions-item>
              <!-- <template v-for="(item,index) in current.selfSealRelList" :key="index">
              </template> -->
              <a-descriptions-item label="申请时间">{{ current?.createTime || '-' }}</a-descriptions-item>
              <a-descriptions-item label="预计用印日期">{{ current?.sealTime || '-' }}</a-descriptions-item>
              <a-descriptions-item label="申请事由">{{ current?.applyText || '无' }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="1"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <a-descriptions-item label="附件">
                <!-- <div v-if="current.documentApplyFile?.length > 0 && visible">
                  <div class="seal-imgs" v-for="(item, index) in current.documentApplyFile" :key="item.fileId">
                    <img
                      :src="item.fullFile + '?uploadFile=500x500'"
                      @click="previewImgObject(index)"
                      alt=""
                      class="seal-img"
                    />
                    <img src="@/assets/images/playIcon.png" @click="previewImgObject(index)" alt="" class="playIcon">
                  </div>
                </div> -->

                <!-- 附件 -->
                <div v-if="current.documentApplyFile?.length > 0 && visible">
                  <div v-viewer="printImgOptions">
                    <section class="show-img-section">
                      <div v-for="(item, index) in current.documentApplyFile" :key="index">
                        <div class="show-img-div" v-if="item.fileType === 1">
                          <!-- + '?uploadFile=500x500' -->
                          <img :src="item.fullFile" alt="" :data-imageType="item.imageType" class="show-img" />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <span v-else>无</span>
              </a-descriptions-item>
            </a-descriptions>
          </section>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, ref, nextTick } from 'vue'
import { warningStatusObj, paginationParams } from '@/utils/constData'
import PhotoView from '@/components/PhotoView'
import '@/style/jsx-label-text.scss'
import { api as viewerApi } from 'v-viewer'

export default defineComponent({
  components: {
    PhotoView
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => {}
    },
    currentLoading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const state = reactive({
      mark: undefined
    })
    const carousel = ref(null)
    const photoIndex = ref(0)
    const previewVisible = ref(false)
    // 通过模板渲染的方式-图片放大
    // const previewImgObject = index => {
    //   const $viewer = viewerApi({
    //     options: {
    //       navbar: false,
    //       title: false,
    //       transition: false,
    //       toolbar: true,
    //       url: 'fullFile',
    //       initialViewIndex: index
    //     },
    //     images: props.current.documentApplyFile
    //   })
    // }
    watch(
      () => props.visible,
      () => props.current,
      newVisible => {
        if (newVisible) {
          state.mark = undefined
        }
      }
    )
    return {
      state,
      warningStatusObj,
      previewVisible,
      carousel,
      photoIndex,
      // previewImgObject,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.ant-modal-title) {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}
:deep(.ant-modal-body) {
  padding: 0;
}
:deep(.ant-timeline-item-last) {
  padding-bottom: 0;
}
.content {
  padding: 24px 24px;
  max-height: 519px;
  overflow-y: auto;
  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }
  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }
  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }
    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }
  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
  }
  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
  .seal-imgs {
    // border: 1px solid rgba(0, 0, 0, 0.15);
    // padding: 6px;
    width: 74px;
    height: 74px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: inline-block;
    margin-right: 12px;
    cursor: pointer;
    line-height: 0;
    position: relative;
    // &:nth-last-of-type(1) {
    //   margin-right: 0;
    // }
    .playIcon {
      position: absolute;
      top: 25px;
      left: 25px;
      width: 24px;
      height: 24px;
    }
    .seal-img {
      margin-left: 5px;
      margin-right: 7px;
      margin-top: 5px;
      width: 63px;
      height: 63px;
      // transform: rotateZ(180deg);
      &.normal {
        transform: rotateZ(0);
      }
    }
  }
}
.ant-carousel :deep(.slick-dots) {
  position: relative;
  height: auto;
}
.ant-carousel :deep(.slick-slide img) {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel :deep(.slick-arrow) {
  display: none !important;
}
.ant-carousel :deep(.slick-thumb) {
  bottom: 0px;
}
.ant-carousel :deep(.slick-thumb li) {
  width: 60px;
  height: 45px;
}
.ant-carousel :deep(.slick-thumb li img) {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel :deep .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>
<style lang="less" scoped>
.show-img-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .show-img-div {
    width: 74px;
    height: 74px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 6px;
    box-sizing: border-box;
    margin-right: 16px;
    margin-bottom: 8px;
    .show-img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
</style>
